import React, { useState } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { GoMail } from "react-icons/go"
import { FaMapMarkedAlt } from "react-icons/fa"
import { FaArrowCircleRight } from "react-icons/fa"

function ContactPage() {
  const localePhilippines = {
    addressTitle: "Martindale Building",
    address: "Blk32 Lot8 Jasmin St, TS Cruz Ave, Almanza Dos, Las Piñas, 1750 Metro Manila | (02) 8362 4894",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15456.303514251269!2d121.0215152!3d14.4227893!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397d12ef6ac720d%3A0x19802f8005e0ed50!2sMartindale%20Technologies%20Inc!5e0!3m2!1sen!2sph!4v1711973494247!5m2!1sen!2sph",
    directory: [
      {
        contactPerson: "Dale Ansay",
        contactTitle: "VP Operations",
        phone: "(+63) 917 532 6729",
        email: "info@martindaletech.com",
      }
    ]
  }
  const localePNG = {
    addressTitle: "PNG Head Office",
    address: "G5FF+2CW, Scratchley Rd, Port Moresby National Capital District, Papua New Guinea",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d584.9948509353055!2d147.17342310607273!3d-9.477331693909658!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x69023105bbe64f07%3A0x4ea455495e044dae!2sAssociated%20Builders%20%26%20Contractors%20Limited!5e0!3m2!1sen!2sph!4v1711978009376!5m2!1sen!2sph",
    directory: [
      {
        contactPerson: "Dennis Velasco",
        contactTitle: "Operations Manager",
        email: "dennis.velasco@martindaletech.com",
        phone: "(+675) 7284 1688",
      }
    ]
  }

  const localeAustralia = {
    addressTitle: "Australia Head Office",
    address: "20 Bolton Street, Melton South Victoria 3338, Australia",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3156.340407046857!2d144.55626329999998!3d-37.7116869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad69318144081b5%3A0x4cb3bc3bc86c3540!2s20%20Bolton%20St%2C%20Melton%20South%20VIC%203338%2C%20Australia!5e0!3m2!1sen!2sph!4v1711977899466!5m2!1sen!2sph",
    directory: [
      {
        contactPerson: "Francisco Lineses",
        contactTitle: "Technical Manager",
        email: "francisco.lineses@martindaletech.com",
        phone: "(+61) 401 223 688",
      },
      {
        contactPerson: "Carlo Manligas",
        contactTitle: "Operations Manager",
        email: "carlo.manligas@martindaletech.com",
        phone: "(+61) 401 223 688",
      }
    ]
  }
  const [localeState, setLocaleState] = useState(0)
  const locales = [localePhilippines, localeAustralia, localePNG]
  return (
    <Layout>
      <Seo title="Contact" description="Contact Martindale Technologies Inc." />
      <section className="contact-page section-center">
        <div className="contact-header">
          <h1>Get in Touch with Us</h1>
          <p>
            Simply drop us a note on this page and we'll get you an answer
            quickly.
          </p>
        </div>
        <div className="contact-content">
          <div className="contact-map-content">
            <div className="double-map">
              <div className="map-selection">
                <div className="map-strong">
                  <div className="contact-button-container">
                    <button
                      className={
                        localeState === 0 ? "selection-active" : ""
                      }
                      onClick={() => {
                        setLocaleState(0)
                      }}
                    >
                      Philippines
                    </button>
                    <button
                      className={
                        localeState === 1 ? "selection-active" : ""
                      }
                      onKeyDown={() => {
                        setLocaleState(1)
                      }}
                      onClick={() => {
                        setLocaleState(1)
                      }}
                    >
                      Australia
                    </button>
                    <button
                      className={
                        localeState === 2 ? "selection-active" : ""
                      }
                      onKeyDown={() => {
                        setLocaleState(2)
                      }}
                      onClick={() => {
                        setLocaleState(2)
                      }}
                    >
                      Papua New Guinea
                    </button>
                  </div>
                </div>
              </div>
              <div className="map-container">
                <iframe
                  title={`Find Us - ${locales[localeState].addressTitle}`}
                  className="responsive-iframe"
                  src={locales[localeState].map}
                  allowFullScreen
                  aria-hidden="false"
                  loading="lazy"
                ></iframe>
              </div>
            </div>

            <div className="contact-info">
              <FaMapMarkedAlt className="contact-icon"></FaMapMarkedAlt>
              <p className="contact-strong">
                {locales[localeState].addressTitle.toUpperCase()}
              </p>
            </div>
            <div className="contact-address">
              <div>
                <p>
                  {locales[localeState].address}
                </p>
              </div>
            </div>
            <div className="contact-info">
              <GoMail className="contact-icon"></GoMail>
              <p className="contact-strong">
                DIRECTORY
              </p>
            </div>
            <div className="directory-container">
              {
                locales[localeState].directory.map((person, index) => {
                  return (
                    <div key={index} className="contact-person">
                      <div className="person-info">
                        <p>
                          {person.contactPerson}
                        </p>
                        <p>
                          {person.contactTitle}
                        </p>
                        <p>
                          {person.phone}
                        </p>
                        <p>
                          {person.email}
                        </p>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>

          <div className="contact-form-content">
            <div className="form-group">
              <form action="https://formspree.io/xyynbznk" method="POST">
                <div>
                  <div>
                    <label htmlFor="first-name" id="first-name-lbl" nesting="">
                      First Name
                    </label>
                    <input
                      name="first-name"
                      id="first-name"
                      type="text"
                      placeholder="First Name"
                      className="form-control"
                    ></input>
                  </div>
                  <div>
                    <label htmlFor="last-name" id="last-name-lbl" nesting="">
                      Last Name
                    </label>
                    <input
                      name="last-name"
                      id="last-name"
                      type="text"
                      placeholder="Last Name"
                      className="form-control"
                    ></input>
                  </div>
                </div>
                <div>
                  <div>
                    <label htmlFor="email" id="email-lbl" nesting="">
                      Email
                    </label>
                    <input
                      name="email"
                      id="email"
                      type="email"
                      placeholder="Email"
                      className="form-control"
                    ></input>
                  </div>
                  <div>
                    <label htmlFor="phone" id="phone-lbl" nesting="">
                      Phone number
                    </label>
                    <input
                      name="phone"
                      id="phone"
                      type="tel"
                      placeholder="Phone"
                      className="form-control"
                    ></input>
                  </div>
                </div>
                <label htmlFor="message" id="message-lbl" nesting="">
                  Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  placeholder="How can we help?"
                  rows="10"
                  className="form-control"
                ></textarea>

                <div style={{ display: "flex" }}>
                  <button type="submit" className="btn btn-center">
                    Submit
                    <FaArrowCircleRight className="btn-icon" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
